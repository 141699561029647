<template>
  <section id="dashboard-ecommerce">
    
    <!--   <b-row class="match-height">
      <b-col md="12">
        <SynchronizedChart />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <b-col md="3" v-for="n in header" v-bind:key="n.id">
        <b-card no-body>
          <b-card-header>
            
            <div>
              <b-card-title class="mb-1">
                {{n.Title}}
                <feather-icon size="32" :icon="n.Icon" class="mr-50"/>
              </b-card-title>
              
            </div>
          </b-card-header>
            <!--/ title and subtitle -->
          <b-card-body style="font-size: 40px; text-align:center">
            {{n.Number}}
            
          </b-card-body>
        </b-card>
      </b-col>
            <b-col md="1">
      </b-col>
    </b-row>


    <b-row class="match-height">
        <b-col md="6">
        <b-card no-body>
          <b-card-header>
            
            <div>
              <b-card-title class="mb-1">
                Top 5 Active Users
              </b-card-title>
              
            </div>
          </b-card-header>
            <!--/ title and subtitle -->
          <b-card-body>
      <div>
       <b-table class="text-left" sticky-header striped hover :fields = "tableColumns" :items="items"></b-table>
     </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- <b-row class="match-height">
      <b-col md="6">
        <ZoommableGraph />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,BCardSubTitle, BTable, BImg } from 'bootstrap-vue'

//import { getUserData } from '@/auth/utils'
import { GET_API,POST_API } from "../../store/actions.type"
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import moment from "moment-timezone";
import Bus from "../../event-bus";

export default {
  components: {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,VueApexCharts,BDropdown, BDropdownItem,flatPickr,BCardSubTitle, BTable, BImg

  },
  data() {
    return {

      tableColumns: [
                { key: 'name', label: 'Name', sortable: false , thStyle:  {width: '70%'}}, 
                { key: 'completed_orders', label: 'Completed Orders', sortable: false , thStyle:  {width: '30%'}},  
            ],
      header: [],

      items: [{}],

      items2: [{}],
    }
  },
  methods:{
        getTopUsersCount(){

      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/top-users-count'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.items = data;
            }
        });
    },
      getLowestUsersCount(){

      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/lowest-users-count'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.items2 = data;
            }
        });
    },
  },
  mounted() { 
    this.getTopUsersCount();
    //this.getLowestUsersCount();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
